import React from "react";
import Page from "../../../routes/Page";
import "./Template2.scss";
import Card from "../../Card/Card";
import Input from "../../Input/Input";

const Template2 = () => {
  const [background, setBackground] = React.useState("#F5F1EA");
  const [text, setText] = React.useState("#000000");

  const [group, setGroup] = React.useState("V1234567890");
  const [institution, setInstitution] = React.useState("Roskilde Universitet");

  const [title, setTitle] = React.useState(
    "Lorem ipsum dolor sit amet, consectetur"
  );
  const [byline, setByline] = React.useState("John Doe, Jane Doe, Jade Doe");

  const [mentor, setMentor] = React.useState("James Doe");
  const [date, setDate] = React.useState("DECEMBER 2023");
  return (
    <Page
      options={
        <>
          <div className="list">
            <Card className="optionGroup">
              <h2 className="title">Color</h2>
              <Input
                type="color"
                value={background}
                label="Background color"
                onChange={(e) => setBackground(e.target.value)}
              />
              <Input
                type="color"
                value={text}
                label="Text color"
                onChange={(e) => setText(e.target.value)}
              />
            </Card>

            <Card className="optionGroup">
              <h2 className="title">Header</h2>
              <Input
                value={group}
                label="Group number"
                onChange={(e) => setGroup(e.target.value)}
              />
              <Input
                value={institution}
                label="Institution"
                onChange={(e) => setInstitution(e.target.value)}
              />
            </Card>

            <Card className="optionGroup">
              <h2 className="title">Hero</h2>
              <Input
                value={title}
                label="Title"
                onChange={(e) => setTitle(e.target.value)}
              />
              <Input
                value={byline}
                label="Byline"
                onChange={(e) => setByline(e.target.value)}
              />
            </Card>

            <Card className="optionGroup">
              <h2 className="title">Footer</h2>
              <Input
                value={mentor}
                label="Mentor"
                onChange={(e) => setMentor(e.target.value)}
              />
              <Input
                value={date}
                label="Date"
                onChange={(e) => setDate(e.target.value)}
              />
            </Card>
          </div>
        </>
      }
      preview={
        <>
          <div
            className="Cover Template2"
            style={{ "--background": background, "--text": text }}
          >
            <div className="Header">
              <div className="Group">
                <p>GRUPPE</p>
                <p>{group}</p>
              </div>
              <p className="institution">{institution}</p>
            </div>
            <div className="Hero">
              <h1>{title}</h1>
              <p className="byline">AF {byline}</p>
            </div>
            <div className="Footer">
              <div className="Mentor">
                <p>VEJLEDER</p>
                <p>{mentor}</p>
              </div>
              <p className="Date">{date}</p>
            </div>
          </div>
        </>
      }
    />
  );
};

export default Template2;
