import React from "react";
import "./Template1.scss";
import Page from "../../../routes/Page";
import Card from "../../Card/Card";
import Input from "../../Input/Input";
import Checkbox from "../../Checkbox/Checkbox";

import { RUC } from "../../logos";

const Template1 = ({}) => {
  const [date, setDate] = React.useState("December 2023");
  const [group, setGroup] = React.useState("V1234567890");
  const [image, setImage] = React.useState("HumTek X");
  const [title, setTitle] = React.useState(
    "Lorem ipsum dolor sit amet consectetur "
  );
  const [subtitle, setSubtitle] = React.useState(
    "adipisicing elit. Ullam nisi rerum cum ad delectus ducimus! Dolor, pariatur vel."
  );
  const [members, setMembers] = React.useState("John Doe, Jane Doe");
  const [mentors, setMentors] = React.useState("Jade Doe");

  const [background, setBackground] = React.useState("#353f82");
  const [accent, setAccent] = React.useState("#f1bd81");
  const [text, setText] = React.useState("#ffffff");

  const [imageEnabled, setImageEnabled] = React.useState(true);
  const [activeImage, setActiveImage] = React.useState(0);

  return (
    <Page
      options={
        <>
          <div className="list">
            <Card className="optionGroup">
              <h2 className="title">Color</h2>
              <Input
                type="color"
                value={background}
                label="Background color"
                onChange={(e) => setBackground(e.target.value)}
              />
              <Input
                type="color"
                value={accent}
                label="Accent color"
                onChange={(e) => setAccent(e.target.value)}
              />
              <Input
                type="color"
                value={text}
                label="Text color"
                onChange={(e) => setText(e.target.value)}
              />
            </Card>

            <Card className="optionGroup">
              <h2 className="title">Header</h2>
              <Input
                value={date}
                label="Date"
                onChange={(e) => setDate(e.target.value)}
              />
              <Input
                value={group}
                label="Group number"
                onChange={(e) => setGroup(e.target.value)}
              />
              <Input
                value={image}
                label="Institution"
                onChange={(e) => setImage(e.target.value)}
              />
              <Checkbox
                value={imageEnabled}
                label="Image enabled"
                onChange={(e) => setImageEnabled(!imageEnabled)}
              />
            </Card>

            <Card className="optionGroup">
              <h2 className="title">Hero</h2>
              <Input
                value={title}
                label="Title"
                onChange={(e) => setTitle(e.target.value)}
              />
              <Input
                value={subtitle}
                label="Subtitle"
                onChange={(e) => setSubtitle(e.target.value)}
              />
            </Card>

            <Card className="optionGroup">
              <h2 className="title">People</h2>
              <Input
                value={members}
                label="Members"
                onChange={(e) => setMembers(e.target.value)}
              />
              <Input
                value={mentors}
                label="Mentors"
                onChange={(e) => setMentors(e.target.value)}
              />
            </Card>
          </div>
        </>
      }
      preview={
        <>
          <div
            className="Cover Template1"
            style={{
              "--accent": accent,
              "--background": background,
              "--text": text,
            }}
          >
            <div className="CoverHeader">
              <div className="Info">
                <h3 className="Date">{date}</h3>
                <h3 className="Group">Gruppe: {group}</h3>
              </div>
              <div className="CoverImage">
                {imageEnabled && <RUC color={accent} />}
                <p className="ImageText">{image}</p>
              </div>
            </div>
            <div className="CoverBody">
              <div className="CoverHero">
                <h1 className="Title">{title}</h1>
                <h2 className="Subtitle">{subtitle}</h2>
              </div>
              <div className="People">
                <div className="Group">
                  <h3 className="Title">Medlemmer</h3>
                  <p className="Names">{members}</p>
                </div>
                <div className="Mentors">
                  <h3 className="Title">Vejledere</h3>
                  <p className="Names">{mentors}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    />
  );
};

export default Template1;
