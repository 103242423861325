import React from "react";

const RUC = ({ color }) => {
  return (
    <svg
      width="83"
      height="35"
      viewBox="0 0 83 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <path
          id="Vector"
          d="M15.8167 1.0481C17.5286 2.30582 19.2755 4.82126 19.7995 6.77772C20.0091 7.58126 20.1139 9.39797 20.0441 10.8304C19.8344 14.2542 18.7514 16.4202 15.8866 18.9706L13.7555 20.9271L18.0527 27.9144L22.3848 34.9367H27.7651H33.1453L31.7828 33.9585C28.4289 31.5129 26.3676 28.1939 25.7388 24.1063C25.5291 22.8486 25.3544 16.9094 25.3544 10.9002V-3.8147e-06H19.8694H14.3843L15.8167 1.0481Z"
          fill={color}
        />
        <path
          id="Vector_2"
          d="M34.8572 12.5423C34.962 24.5955 34.997 25.1544 35.6957 26.0628C37.3028 28.2289 39.7833 29.2071 42.2638 28.6481C43.9757 28.2638 45.2334 27.3555 46.1418 25.8182C46.8056 24.7003 46.8405 23.8967 46.9453 12.2977L47.0501 1.71661e-05H40.9013H34.7524L34.8572 12.5423Z"
          fill={color}
        />
        <path
          id="Vector_3"
          d="M56.0289 12.8567L55.9241 25.7134L54.6314 28.3686C53.4086 30.8491 51.6967 32.7706 49.4608 34.3078C48.6223 34.8668 49.1813 34.9018 60.2562 34.9018C66.6846 34.9367 71.8203 34.797 71.6106 34.6572C71.436 34.5175 70.3529 33.7838 69.2349 33.0152C65.6015 30.5696 62.9114 26.3423 61.8633 21.4511C61.2344 18.4116 61.2344 16.5251 61.8633 13.4506C62.9114 8.3848 65.8111 4.05265 69.8289 1.4324L71.9949 -7.62939e-06H64.0643H56.1337L56.0289 12.8567Z"
          fill={color}
        />
        <path
          id="Vector_4"
          d="M0.200012 10.6906V15.442L3.79849 15.3023C7.85115 15.1276 9.24862 14.6035 10.3317 12.8218C11.7641 10.5159 10.9256 7.721 8.48001 6.60303C7.36204 6.11391 6.20913 5.93923 3.62381 5.93923H0.200012V10.6906Z"
          fill={color}
        />
        <path
          id="Vector_5"
          d="M77.7246 6.42835C73.1479 8.07037 70.7023 11.8435 70.6674 17.2937C70.5975 21.6957 72.1347 24.9797 75.279 27.2157C76.6765 28.2289 80.1003 29.0673 81.7074 28.7878L83 28.5782L82.9302 17.3635L82.8254 6.11392L80.9038 6.04404C79.8557 5.97417 78.4233 6.14885 77.7246 6.42835Z"
          fill={color}
        />
        <path
          id="Vector_6"
          d="M0.200012 28.8228V34.9367H6.17419C9.4233 34.9367 12.0436 34.8319 11.9737 34.6572C11.9038 34.5175 10.2967 31.7924 8.44508 28.5433L5.02128 22.7089H2.61065H0.200012V28.8228Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default RUC;
