import React from "react";
import GlowBorder from "../GlowBorder/GlowBorder";
import "./Input.scss";

const Input = (props) => {
  return (
    <div className="InputContainer">
      {props.label && <label>{props.label}</label>}
      <input type={props.type || "text"} {...props} />
    </div>
  );
};

export default Input;
