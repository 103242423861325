import React from "react";
import "./global.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Page from "./routes/Page";
import Card from "./components/Card/Card";
import templates from "./routes/templates";
import TemplateCard from "./components/TemplateCard/TemplateCard";

const App = () => {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Page
                  options={
                    <>
                      <h1 className="title">Select template</h1>
                      <div className="templates">
                        {templates.map((template, index) => (
                          <TemplateCard
                            name={template.name}
                            image={template.image}
                            key={index}
                            to={template.route}
                          />
                        ))}
                      </div>
                    </>
                  }
                  preview={
                    <>
                      <h3>Select a template to get started</h3>
                    </>
                  }
                  noPreview
                  noBack
                />
              </>
            }
          />
          {templates.map((template, index) => (
            <Route path={template.route} element={template.element} />
          ))}
        </Routes>
      </Router>
    </div>
  );
};

export default App;
