import React from "react";
import "./GlowBorder.scss";

const GlowBorder = (props) => {
  const glanse = React.useRef(null);
  const container = React.useRef(null);

  React.useEffect(() => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (!isSafari) {
      const mouseMoveHandler = (e) => {
        const containerBounds = container.current.getBoundingClientRect();
        glanse.current.style.left = e.clientX - containerBounds.left + "px";
        glanse.current.style.top = e.clientY - containerBounds.top + "px";
      };

      window.addEventListener("mousemove", mouseMoveHandler);

      return () => {
        window.removeEventListener("mousemove", mouseMoveHandler);
      };
    }
  }, []);

  return (
    <div
      className={`GlowBorder${
        props.borderClassName ? " " + props.borderClassName : ""
      }`}
      ref={container}
      style={{
        "--border-size": props.borderSize || "1px",
        "--border-color": props.borderColor || "var(--dark-400)",
        "--border-radius": props.borderRadius || "var(--radius)",
      }}
      onMouseEnter={(e) => (props.onMouseEnter ? props.onMouseEnter(e) : null)}
      onMouseLeave={(e) => (props.onMouseLeave ? props.onMouseLeave(e) : null)}
      onMouseMove={(e) => (props.onMouseMove ? props.onMouseMove(e) : null)}
      onClick={(e) => (props.onClick ? props.onClick(e) : null)}
    >
      <div className="border-glanse" ref={glanse}></div>
      <div
        className={`glowBorderContent${
          props.className ? " " + props.className : ""
        }`}
      >
        {props.children}
      </div>
    </div>
  );
};

export default GlowBorder;
