import React from "react";
import "./TemplateCard.scss";
import Card from "../Card/Card";

const TemplateCard = ({ name, image, to }) => {
  const img = React.useRef(null);
  const [hover, setHover] = React.useState(true);

  const handleMouseMove = (e) => {
    if (hover) {
      const x = e.clientX / window.innerWidth;
      const y = e.clientY / window.innerHeight;
      img.current.style.transform = `translate(-${x * 20}px, -${y * 20}px)`;
    }
  };

  return (
    <Card
      className="TemplateCard"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onMouseMove={(e) => handleMouseMove(e)}
      onClick={() => window.open(to, "_self")}
    >
      <h2>{name}</h2>
      <img ref={img} src={image} alt="" />
    </Card>
  );
};

export default TemplateCard;
