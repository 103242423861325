import React from "react";
import "./Checkbox.scss";
import { Check } from "tabler-icons-react";

const Checkbox = (props) => {
  return (
    <div className="Checkbox" onClick={() => props.onChange()}>
      <input
        type="checkbox"
        id="checkbox"
        ref={props.ref}
        checked={props.value}
        style={{ display: "none" }}
      />
      <div className={`checkboxElement ${props.value ? "checked" : ""}`}>
        <Check size={13} />
      </div>
      <label>{props.label}</label>
    </div>
  );
};

export default Checkbox;
