import React from "react";
import "./Card.scss";
import GlowBorder from "../GlowBorder/GlowBorder";

const Card = (props) => {
  return (
    <div
      {...props}
      className={`Card ${props.className ? props.className : ""}`}
    >
      {props.children}
    </div>
  );
};

export default Card;
