import React from "react";
import "./Page.scss";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import Button from "../components/Button/Button";

const Page = (props) => {
  const saveCover = () => {
    console.log("hello");
    const coverDiv = document.querySelector(".Cover");
    coverDiv.classList.add("saving");
    html2canvas(coverDiv).then((canvas) => {
      canvas.toBlob((blob) => {
        saveAs(blob, `cover.png`);
      });
      coverDiv.classList.remove("saving");
    });
  };

  return (
    <div className="Page">
      <div className="options">
        {!props.noBack && (
          <Button href="/" className="back">
            Select template
          </Button>
        )}
        {props.options}
      </div>
      <div className="preview">
        {props.preview}
        {!props.noPreview && (
          <Button className="save" onClick={() => saveCover()}>
            Save cover
          </Button>
        )}
      </div>
    </div>
  );
};

export default Page;
