import React from "react";
import "./Button.scss";
import GlowBorder from "../GlowBorder/GlowBorder";

const Button = (props) => {
  return (
    <a
      {...props}
      className={`Button unstyled ${props.className || ""}`}
      href={props.href ? props.href : "#"}
    >
      {props.children ? props.children : "Button"}
    </a>
  );
};

export default Button;
