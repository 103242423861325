import Template1 from "../components/covers/Template1/Template1";
import template1 from "../components/covers/Template1/Template1.png"

import Template2 from "../components/covers/Template2/Template2";
import template2 from "../components/covers/Template2/Template2.png"

const templates = [
  {name: "Modern", route: '/modern', element: <Template1 />, image: template1},
  {name: "Simple", route: '/simple', element: <Template2 />, image: template2},
]

export default templates;